<template>
  <div class="app-form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Почта</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.email">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Пароль</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.password">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Повторите пароль</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.password">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Включен</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.is_active">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Роль</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.role_id">
                <option
                  v-for="option in roles"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label">
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { addUser } from '@/api/user'
// import { getRoles } from '@/api/role'
import { state_yesno, state_show, EventBus } from '@/utils'

export default {
  name: 'UserFormAdd',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    const roles = []
    return {
      state_yesno: state_yesno,
      state_show: state_show,
      roles: roles,
      model: {

      }
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  mounted: function(){

    // getRoles(this.$store.state.jwt_user)
    //   .then(response => {
    //     let data = response.data;
    //     data.data.forEach((item) => {
    //       this.roles.push(item)
    //     })
    //   })
    //   .catch(
    //     this.roles = []
    //   )

  },
  methods: {
    doUpdate () {
      let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          addUser($this.model, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successAddUser')
              this.$router.push({ name: 'a-users', params: {} })
            }
          )        

        }
      })
    },
  }
}
</script>

<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style>
</style>